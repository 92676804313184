<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- 回頂端 -->
        <a id="toTop" class="toTop icon iconButton" onclick="toTop()">
            <font-awesome-icon
                icon="fa-solid fa-chevron-up"
            ></font-awesome-icon>
        </a>
        <!-- Main -->
        <section id="main" class="main store">
            <a
                class="back icon iconButton third"
                @click.prevent="$router.push('/stores')"
            >
                <font-awesome-icon
                    icon="fa-solid fa-chevron-left"
                ></font-awesome-icon>
            </a>
            <img class="cover" src="@/assets/img/ticket-cover.png" />
            <!-- <img class="profile" src="@/assets/img/ticket-profile.png" /> -->
            <!-- class: active 切換模式 -->
            <!-- <a class="favorite flexV active">
                <div class="icon iconButton">
                    <font-awesome-icon
                        icon="fa-solid fa-heart"
                        sieze="xl"
                        class="error"
                    ></font-awesome-icon>
                </div>
                <div class="icon iconButton">
                    <font-awesome-icon
                        icon="fa-regular fa-heart"
                        size="xl"
                        class="minor"
                    ></font-awesome-icon>
                </div>
            </a> -->
            <span class="fL">{{ storeInfo.name }}</span>
            <span class="fM gray">{{ storeInfo.categoryName }}</span>
            <!-- Tabs -->
            <div class="tabs line fM rounded">
                <a v-if="enableStamp" class="tab">
                    <span>優惠券</span>
                </a>
                <div v-if="enableStamp" class="lineV"></div>
                <a v-if="enableStamp" class="tab" @click.prevent="$router.push(stampLink)">
                    <span>集章</span>
                </a>
                <div v-if="enableStamp" class="lineV"></div>
                <a class="tab active">
                    <span>店家資訊</span>
                </a>
            </div>

            <!-- 店家資訊 -->
            <div class="flexH width margin padding between">
                <div class="flexH">
                    <div class="icon third">
                        <font-awesome-icon
                            icon="fa-solid fa-map"
                        ></font-awesome-icon>
                    </div>
                    <a 
                        class="textButton fM"
                        :href="`https://www.google.com/maps/search/?api=1&query_place_id=${storeInfo.storePlaceId}&query=${storeInfo.address}`"
                        >{{ storeInfo.address }}</a
                    >
                    <!-- <a
                        class="textButton fM"
                        :href="`https://www.google.com/maps/search/?api=1&query=${storeInfo.address}`"
                        >{{ storeInfo.address }}</a
                    > -->
                    <input ref="address" type="text" :value="storeInfo.address" style="display:none;"/>
                </div>
                <a class="icon gray" @click.prevent="copy('address')"
                    ><font-awesome-icon
                        icon="fa-solid fa-copy"
                    ></font-awesome-icon
                ></a>
            </div>
            <div class="flexH width margin padding between">
                <div class="flexH">
                    <div class="icon third">
                        <font-awesome-icon
                            icon="fa-solid fa-phone"
                        ></font-awesome-icon>
                    </div>
                    <a class="textButton fM" :href="`tel:${storeInfo.phone}`">{{
                        storeInfo.phone
                    }}</a>
                </div>
                <a class="icon gray" @click.prevent="copy('phone')"
                    ><font-awesome-icon
                        icon="fa-solid fa-copy"
                    ></font-awesome-icon
                ></a>
                <input ref="phone" type="text" :value="storeInfo.phone" style="display:none;"/>
            </div>
            <div class="img testImg">
                <div class="map" id="map"></div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    name: "",
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            storeApiHost: process.env.VUE_APP_STORE_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            storeId: null,
            storeInfo: {},
            stampLink: ""
        };
    },
    computed: {
        enableStamp(){
            return process.env.VUE_APP_ENABLE_STAMP ==='true' ;
        }
    },
    async mounted() {
        this.storeId = this.$route.params.id;
        this.stampLink = `/stores/${this.storeId}/stamp-cards`;
        await this.getStoreInfo();
        this.drawMap();
    },
    methods: {
        dummyStoreInfo() {
            let result = {
                name: "測試商店",
                address: "測試地址",
                phone: "0987654321",
                banId: "85111267",
                storePlaceId: "ChIJx3Qv-KchaDQRA1lVKGSYsVQ",
                geometry: JSON.stringify({
                    location: {
                        lat: 25.0109536,
                        lng: 121.2173832
                    },
                    viewport: {
                        south: 25.0100204697085,
                        west: 121.2164427697085,
                        north: 25.0127184302915,
                        east: 121.2191407302915
                    }
                }),
                categoryName: "餐飲",
                categoryId: 1,
                addressComponents: JSON.stringify([
                    {
                        long_name: "9",
                        short_name: "9",
                        types: ["street_number"]
                    },
                    {
                        long_name: "航站南路",
                        short_name: "航站南路",
                        types: ["route"]
                    },
                    {
                        long_name: "大園區",
                        short_name: "大園區",
                        types: ["administrative_area_level_3", "political"]
                    },
                    {
                        long_name: "桃園市",
                        short_name: "桃園市",
                        types: ["administrative_area_level_1", "political"]
                    },
                    {
                        long_name: "台灣",
                        short_name: "TW",
                        types: ["country", "political"]
                    },
                    {
                        long_name: "33758",
                        short_name: "33758",
                        types: ["postal_code"]
                    }
                ])
            };
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({ data: result });
                }, 300);
            });
        },
        callGetStoreInfoAPI() {
            let config = {
                url: `${this.storeApiHost}/stores/v1/merchants/${this.merchantId}/stores/${this.storeId}`,
                methods: "GET"
            };
            return this.$http(config);
        },
        getStoreInfo() {
            return (
                this.callGetStoreInfoAPI()
                    // return this.dummyStoreInfo()
                    .then((res) => {
                        console.log("getStoreInfo res: ", res);
                        this.storeInfo = res.data;
                        return;
                    })
            );
        },
        setMapCenter(map, pos) {
            map.setCenter({
                lat: pos.lat,
                lng: pos.lng
            });
        },
        drawMap() {
            const map = new google.maps.Map(document.getElementById("map"), {
                center: { lat: 24.9742352, lng: 121.0703047 },
                zoom: 13,
                mapTypeId: "roadmap",
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false
            });
            const service = new google.maps.places.PlacesService(map);
            const request = {
                placeId: this.storeInfo.storePlaceId,
                fields: ["name", "place_id", "geometry", "address_component"]
            };
            service.getDetails(request, (place) => {
                this.setMapCenter(
                    map,
                    JSON.parse(JSON.stringify(place.geometry.location))
                );
                new google.maps.Marker({
                    position: JSON.parse(
                        JSON.stringify(place.geometry.location)
                    ),
                    map: map
                });
            });
        },
        copy(ref) {
            const copyText = this.$refs[ref];
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices */
            navigator.clipboard.writeText(copyText.value);
        }
    }
};
</script>
<style scoped src="@/assets/css/google-map.css"></style>
